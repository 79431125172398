import React from "react";
import styled from "styled-components";
import { Title } from "components/title";

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  background-color: #94454566;
  margin-bottom: 1rem;
  text-align: center;

  padding: 32px 8px;

  @media ${({ theme: { mq } }) => mq.tabletMin} {
    padding: 32px 12px;
  }
  @media ${({ theme: { mq } }) => mq.desktopMin} {
    padding: 32px 16px;
  }
`;

const Image = styled.img`
  border-radius: 50%;
  border: 5px solid white;
  height: 200px;
  width: 200px;
`;

export const MainHero = () => {
  return (
    <Background>
      <Image src="https://en.gravatar.com/userimage/82564655/4847d2cf7b231aaa75ae55c30d5f9612.png?size=200" />
      <Title as="h1">Chris Naismith</Title>

      <Title as="h2">Engineer / Mentor / Freelancer</Title>
    </Background>
  );
};
