import React from "react";
import styled from "styled-components";
import { Experience } from "types/index";
import { Text } from "components/text";
import dayjs from "dayjs";

type Props = Experience;

const Container = styled.article`
  margin-bottom: 1rem;
`;

export const ExperienceItem: React.FC<Props> = ({
  children,
  title,
  company,
  location,
  employmentType,
  startDate,
  endDate,
  description
}) => {
  return (
    <Container>
      <Text weight={700} size="20px">
        {title}
      </Text>
      <Text>
        {company} - {employmentType}
      </Text>
      <Text>
        {dayjs(startDate).format("MMM. YYYY")} -{" "}
        {!!endDate ? dayjs(endDate).format("MMM. YYYY") : "Present"}
      </Text>
      <Text>{location}</Text>
    </Container>
  );
};
