import { Recommendation } from "types/index";

export const recommendations: Recommendation[] = [
  {
    body: [
      "Chris is a friendly guy, with a very likeable personality. He’s a great programmer, but the first thing you’ll notice about him is probably his beard. Chris has been an influential contributor to the local tech community with his NorthOT tech meetup, the only one north of Toronto. I first got to really know Chris through NorthOT and our shared passion for podcasting. I was excited for the chance to finally work with Chris when he worked at Finaeo Inc. Chris was a member of their Engineering team and referred me for the position of Product Owner. Since becoming Product Owner at Finaeo, Chris and I had a few months to work together before he moved onto another role. We were sad to see him go, but excited for his continued growth.",
      "Chris continues to be a valuable asset to our company by providing some contract services to augment our engineering team. Any team would be lucky to have him."
    ],
    person: "Steedan Crowe"
  },
  {
    person: "Steve Harvey",
    body:
      "Chris was a critical hire and a key contributor to helping us hit a consistent stride with our platform releases.  Chris meshed fantastically with our team (both within and outside of engineering), and we quickly moved him up into a Lead role within a few sprints of seeing his talents in action. Chris is what I call an 'engineer that can think', and brings more to the table than just his strong repertoire of full stack development skills.  Excited to see where your career takes you, Chris!"
  },
  {
    person: "Thacher Hussain",
    body:
      "Chris is a strong engineer and a great communicator. He has a working knowledge of all aspects of software development and handles all types of challenges with confidence and clarity. Chris’ mentorship has helped me learn more about React JS, using specific JavaScript libraries,  and broader software engineering topics. Working with Chris has enabled me to grow as an engineer and he continues to be a person I reach out to not only for help, but also as a friend and fellow engineer for his thoughts and opinions on specific technology and the industry at large."
  },
  {
    person: "Rutul Patel",
    body:
      "I thoroughly enjoyed my time working with Chris and came to know him as a truly valuable asset to our team. He is honest, dependable, and incredibly hard-working. Beyond that, he is an impressive problem solver who is always able to address complex issues with strategy and confidence. Chris is inspired by challenges and never intimidated by them."
  },
  {
    person: "Harlan Batchelor",
    body:
      "Chris is one of the best software developers I know. He's the kind of guy I always leave conversations from having learned something new - someone who always challenges you to push your own abilities forward. On top of this, he's simply an all-around wonderful person to be around and talk to. His breadth of knowledge and problem solving skills make him an incredibly valuable asset to any team."
  },
  {
    person: "Keith Brewster",
    body:
      "I had the pleasure of working with Chris in various capacities over the past few years (as a colleague and through the tech meetup he runs). During the time we worked together at LabX Media Group, he consistently demonstrated a strong work ethic and a commitment to success. Chris has an inquisitive mind, and he uses that curiosity to deliver results. Besides being a great person to work with, Chris Naismith is a take-charge person who is able to present creative solutions to complex problems, and he would be an asset to any team."
  },
  {
    person: "Chris Gibb",
    body:
      "Chris is a pleasure to work with. A web guru if there ever was one, Chris was my goto for questions and solutions to all things front end. A natural educator and leader, Chris is one of my favourite people to debate and to disagree with. Though we worked together for a relatively short period, his impact on my personal and professional growth was outsized."
  },
  {
    person: "Rich Freeman",
    body:
      "Chris is a bright, personable, engaging, and talented developer and teacher.  He has a broad tool set of development skills and is constantly seeking new and innovative ways to solve problems and make applications better.  He's become a tech leader in the region through his founding of the NorthOT Tech Meetups and he's also giving back through his teaching at Georgian College.  As a student Chris was extremely well-liked and respected by both his peers and faculty.  Chris has quickly built a successful career and will undoubtedly continue to be valuable in any role he chooses."
  },
  {
    person: "Mark Yaraskavitch",
    body:
      "Chris is a pleasure to work with and consistently ahead of the curve with tools and technologies in web development. He brings fresh ideas to team environments and delivers on time as an individual contributor. His exceptional communication skills make him a standout developer, as evidenced by the NorthOT tech group that he founded and ran."
  }
];
