import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -8px;

  @media ${({ theme }) => theme.mq.tabletMin} {
    margin: 0 -12px;
  }

  @media ${({ theme }) => theme.mq.tabletMin} {
    margin: 0 -16px;
  }
`;

type Columns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type GridItemProps = {
  mobile?: Columns;
  tablet?: Columns;
  desktop?: Columns;
}
const Item = styled.div<GridItemProps>`
  padding: 8px;
  width: ${({ mobile }) => `calc(100% / 12 * ${mobile || 12})`};

  @media ${({ theme }) => theme.mq.tabletMin} {
    padding: 12px;
    width: ${({ mobile, tablet }) =>
      `calc(100% / 12 * ${tablet || mobile || 12})`};
  }

  @media ${({ theme }) => theme.mq.tabletMin} {
    padding: 16px;
    width: ${({ mobile, tablet, desktop }) =>
      `calc(100% / 12 * ${desktop || tablet || mobile || 12})`};
  }
`;

export const Grid = {
  Container,
  Item
};
