import React from "react";
import styled from "styled-components";
import { Text } from "components/text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faGithubSquare } from "@fortawesome/free-brands-svg-icons";
const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Item = styled.li`
  margin-bottom: 0.5rem;
  & a {
    display: flex;
    align-items: center;
    color: #111;
    text-decoration: none;
    transition: 100ms color linear;

    :hover {
      color: #804040;
      text-decoration: underline;
    }
  }

  & svg {
    font-size: 30px;
    margin-right: 18px;
  }
`;

const links = [
  {
    icon: faLinkedin,
    text: "LinkedIn",
    href: "https://www.linkedin.com/in/chris-naismith/"
  },
  {
    icon: faGithubSquare,
    text: "GitHub",
    href: "https://www.github.com/naismith"
  },
  { icon: faEnvelopeSquare, text: "Email", href: "mailto:chris@naismith.dev" }
];

export const ContactList = () => {
  return (
    <List>
      {links.map((link) => (
        <Item key={link.text}>
          <a target="_blank" rel="noreferrer" href={link.href}>
            <FontAwesomeIcon icon={link.icon} />
            <Text as="span">{link.text}</Text>
          </a>
        </Item>
      ))}
    </List>
  );
};
