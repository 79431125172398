import styled, { css } from "styled-components";

type Props = {
  fluid?: boolean 
}
export const Container = styled.div<Props>`
  ${({ fluid = false }) =>
    !fluid &&
    css`
      max-width: 800px;
      margin: 0 auto;
    `}

  padding: 0 16px;

  @media ${({ theme: { mq } }) => mq.tabletMin} {
    padding: 0 24px;
  }

  @media ${({ theme: { mq } }) => mq.desktopMin} {
    padding: 0 32px;
  }
`;
