import { Text } from "./text";
import styled from "styled-components";

const fontSize = {
  h1: "50px",
  h2: "32px",
  h3: "32px",
  h4: "20px",
  h5: "20px",
  h6: "20px"
};

const margins = {
  h1: "0 0 0.25em",
  h2: "0",
  h3: "0 0 1rem",
  h4: "20px",
  h5: "20px",
  h6: "20px"
};

const weights = {
  h1: 700,
  h2: 400,
  h3: 400,
  h4: 400,
  h5: 400,
  h6: 400
};

type TitleProps = {
  as: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

export const Title = styled(Text)<TitleProps>`
  font-size: ${({ as, size }) => size || fontSize[as]};
  margin: ${({ as }) => margins[as]};
  font-weight: ${({ as }) => weights[as]};
`;

Title.defaultProps = {
  font: "secondary"
};
