import { Review } from "types/index";

export const reviews: Review[] = [
  {
    mentee: "Tom Ray",
    rating: 5,
    text:
      "Chris has been an excellent mentor in helping me advance my React skills. In just a few weeks, I feel a LOT more confident navigating the React world. It's great that Chris invited me into Slack as well so I can pop over questions whenever I have them. If you're looking to up your JS and React skills, I couldn't recommend Chris more. Thanks Chris!!"
  },
  {
    mentee: "Luke Wilson",
    rating: 5,
    text:
      "Chris is very knowledgeable and generous. He listened to my objectives and has given me a path to follow. He also supports me with very helpful one to one sessions and helps unblock me if I get stuck on a concept. I highly recommend Chris as a developer's mentor."
  },
  {
    mentee: "Thacher Hussain",
    rating: 5,
    text:
      "Chris is great to work with -- he is knowledgeable and helpful with learning new things and with troubleshooting existing issues. I would definitely recommend working with him."
  },
  {
    mentee: "Patrick Campbell",
    rating: 5,
    text: `Chris was very helpful for the short time I needed React mentorship.`
  },

  {
    mentee: "Pavel Iugai",
    rating: 5,
    text:
      "Chris is awesome!!! He has a very friendly personality. He is able to answer all my questions and gives really valuable advices. If you are looking for a great mentor, stop looking! Choose Chris ! =)"
  },

  {
    mentee: "Jeremy V",
    rating: 5,
    text:
      "Chris has been great! He has been very responsive to my questions and has given me suggestions to help me move my development skills forward."
  }
];
