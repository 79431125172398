import React from 'react';
import { Text } from "components/text";
import { recommendations } from "__fixtures__/recommendation";
import { RecommendationItem } from "components/recommendation";
import { MiniHero } from "components/hero";
import { Grid } from "components/grid";
import { Title } from "components/title";
import { Container } from "components/container";


const Recommendations = () => (
    <>
        <MiniHero title="Recommendations">
            <Text>Recommendations from past and present people I've worked with.</Text>
        </MiniHero>
        <Container>
            <Grid.Container>
                <Grid.Item>
                    <Title as="h3">Recommendations</Title>
                    {recommendations.map((recommendation) => (
                    <RecommendationItem
                        key={recommendation.person}
                        {...recommendation}
                    />
                    ))}
                </Grid.Item>
            </Grid.Container>
        </Container>
    </>
)

export default Recommendations;