import React from "react";
import styled, { css, keyframes, useTheme } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { Text } from "components/text";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "hooks/use-media-query";
import { ZeroStylesButton } from "components/button";

const Nav = styled.nav`
  background-color: #111;
  position: relative;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`;

const glowtext = keyframes`
  0% {
    text-shadow: 0px;
  }

  ${[
    "#ff0000",
    "#ff7F00",
    "#ffff00",
    "#00ff00",
    "#0000ff",
    "#4b0082",
    "#9400d3"
  ].map(
    (color, index) => `
    ${index * 10 + 10}% {
      text-shadow: ${color} 1px 0 20px;
    }
  `
  )}
 
  80% {
    text-shadow : 0px;
  }
`;
const NavLink = styled(Text)<{ $active: boolean }>`
  color: white;
  display: block;
  text-decoration: none;
  padding: 12px 16px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;

  &:hover {
    background-color: #333;
    text-decoration: underline;
  }

  ${({ $active: active }) =>
    active &&
    css`
      animation: 20s ${glowtext} linear infinite;
    `}
`;

const MobileContainer = styled.div<{ $hidden: boolean }>`
  opacity: ${({ $hidden }) => ($hidden ? "0" : "1")};
  position: absolute;
  width: 100%;
  top: 100%;
  background-color: #111;

  @media ${({ theme: { mq } }) => mq.tabletMin} {
    width: auto;
    opacity: 1;
    display: flex;
    position: unset;
  }
`;

const Divider = styled.div`
  flex: 1;
`;
const ListItem = styled.li``;

const MobileActivator = styled(ZeroStylesButton)`
  padding: 10px;
  color: white;
  font-size: 14px;
`;

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.mq.tabletMin);
  const location = useLocation();

  React.useEffect(() => {
    setMenuOpen(false);
  }, [isTablet, location]);

  return (
    <Nav>
      <List>
        <ListItem>
          <NavLink
            as={Link}
            $active={location.pathname === "/"}
            title="Back to Home"
            to="/"
          >
            Chris Naismith
          </NavLink>
        </ListItem>
        <Divider />
        {!isTablet && (
          <ListItem>
            <MobileActivator onClick={() => setMenuOpen((p) => !p)}>
              <FontAwesomeIcon icon={faBars} />
            </MobileActivator>
          </ListItem>
        )}
        <MobileContainer $hidden={!menuOpen}>
          <ListItem>
            <NavLink
              as={Link}
              $active={location.pathname === "/experience"}
              to="/experience"
            >
              Experience
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink
              as={Link}
              $active={location.pathname === "/mentorship"}
              to="/mentorship"
            >
              Mentoring
            </NavLink>
          </ListItem>
          <ListItem>
            <NavLink
              as={Link}
              $active={location.pathname === "/recommendations"}
              to="/recommendations"
            >
              Recommendations
            </NavLink>
          </ListItem>
          {/* <ListItem>
            <NavLink
              as={Link}
              $active={location.pathname === "/freelancing"}
              to="/freelancing"
            >
              Freelancing
            </NavLink>
          </ListItem> */}
        </MobileContainer>
      </List>
    </Nav>
  );
};
