import React from "react";
import styled from "styled-components";
import { Text } from "components/text";
import { Title } from "components/title";

const Background = styled.div`
  justify-content: center;
  padding: 32px 0;
  background-color: #94454566;
  margin-bottom: 1rem;
`;

const InnerContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;

  @media (min-width: 440px) {
    padding: 0 24px;
  }

  @media (min-width: 1024px) {
    padding: 0 32px;
  }
`;

export const MiniHero: React.FC<{ title: string }> = ({ children, title }) => {
  return (
    <Background>
      <InnerContainer>
        <Title as="h2" size="50px">
          {title}
        </Title>
        {children}
      </InnerContainer>
    </Background>
  );
};
