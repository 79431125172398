import React from "react";

export const useMediaQuery = (mq: string) => {
  const [matches, setMatches] = React.useState(window.matchMedia(mq).matches);

  React.useEffect(() => {
    const handleMediaQuery = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    window.matchMedia(mq).addListener(handleMediaQuery);

    return () => {
      window.matchMedia(mq).removeListener(handleMediaQuery);
    };
  }, [mq]);

  return matches;
};
