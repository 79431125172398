const getTheme = () => {
  return {
    mq: {
      tabletMin: "(min-width: 440px)",
      desktopMin: "(min-width: 1024px)"
    },
    palette: {
      primary: {
        main: "#804040"
      }
    }
  }
}

export const theme = getTheme();

export type Theme = typeof theme;
