import React from "react";
import { MainHero } from "components/hero";
import { Container } from "components/container";
import { Grid } from "components/grid";
import { Text } from "components/text";
import { ContactList } from "components/contact-list";
import { Title } from "components/title";

const Home = () => (
  <div>
    <MainHero />
    <Container>
      <Grid.Container>
        <Grid.Item mobile={12} tablet={8}>
          <Title as="h3">About Me</Title>
          <Text gutterBottom>
            Chris is a Canadian raised software engineer who currently resides
            in Markdale Ontario (
            <a
              target="_blank"
              rel="noreferrer"
              title="Navigate to Chapman's Ice Cream Home Page"
              href="https://www.chapmans.ca/"
            >
              Home of Chapman's Ice Cream
            </a>
            ).
          </Text>
          <Text gutterBottom>
            Chris's experience covers Teaching, Mentorship, Leadership and
            Engineering. His hobbies include Coffee, Kayaking, Cooking, Baking
            and spending time with his wife and dog.
          </Text>
        </Grid.Item>
        <Grid.Item mobile={12} tablet={4}>
          <Title as="h3">Contact</Title>
          <ContactList />
        </Grid.Item>
      </Grid.Container>
    </Container>
  </div>
);

export default Home;
