import React from "react";
import styled from "styled-components";
import { Text } from "components/text";

const Background = styled.div`
  background-color: #111;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 8px;
`;

const FooterText = styled(Text)`
  text-transform: uppercase;
  font-size: 12px;
`;

export const Footer = () => {
  return (
    <Background>
      <FooterText>
        <span>Made with </span>
        <span aria-label="love" role="img">
          ❤️
        </span>
      </FooterText>
      <FooterText>© {new Date().getFullYear()} - Chris Naismith</FooterText>
    </Background>
  );
};
