import React from "react";
import { MiniHero } from "components/hero";
import { Text } from "components/text";
import { Container } from "components/container";
import { experience } from "__fixtures__/experience";
import { ExperienceItem } from "components/experience";
import { Grid } from "components/grid";
import { Title } from "components/title";

const Experience = () => {
  return (
    <>
      <MiniHero title="Experience">
        <Text>Some words that explain what makes me tick</Text>
      </MiniHero>
      <Container>
        <Grid.Container>
          <Grid.Item>
            <Title as="h3">History</Title>
          </Grid.Item>
          {experience.map((exp, index) => (
            <Grid.Item tablet={6} key={index}>
              <ExperienceItem {...exp} />
            </Grid.Item>
          ))}

          <Grid.Item>
            <Title as="h3">Skills</Title>
            <Text gutterBottom>HTML / CSS / JavaScript</Text>
            <Text gutterBottom>
              LESS, SCSS, JSS, Styled-Components, Tailwind
            </Text>
            <Text gutterBottom>ES2015+, Typescript</Text>
            <Text gutterBottom>React, Redux, Webpack, Parcel</Text>
            <Text gutterBottom>Netlify, AWS, GCP, Azure, Digital Ocean</Text>
          </Grid.Item>
          
        </Grid.Container>
      </Container>
    </>
  );
};

export default Experience;
