import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html,body,#root {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  *::before,
  *::after {
    box-sizing: inherit;
  }
`;
