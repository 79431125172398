import styled, { css } from "styled-components";

type TextProps = {
  font?: "primary" | "secondary";
  weight?: 300 | 400 | 700;
  size?: string;
  gutterBottom?: boolean;
};

export const Text = styled.p<TextProps>`
  font-family: ${({ font = "primary" }) =>
    font === "primary" ? `'Raleway', Sans-serif` : `'Open Sans', Sans-serif`};
  font-size: ${({ size = "16px" }) => size};
  font-weight: ${({ weight = 300 }) => weight};
  letter-spacing: 1.5px;
  margin: 0;

  ${({ gutterBottom }) =>
    gutterBottom &&
    css`
      margin-bottom: 0.5em;
    `}
`;
