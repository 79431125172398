import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "pages/home";
import Mentorship from "pages/mentorship";
import Experience from "pages/experience";
import Recommendations from "pages/recommendations";
// import Freelancing from "pages/freelancing";

import { Footer, Navbar, Layout, Main } from "components/layout";

export const Router = () => (
  <BrowserRouter>
    <Layout>
      <Navbar />
      <Main>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/mentorship" component={Mentorship} />
          <Route exact path="/experience" component={Experience} />
          <Route exact path="/recommendations" component={Recommendations} />
          {/* <Route exact path="/freelancing" component={Freelancing} /> */}
        </Switch>
      </Main>
      <Footer />
    </Layout>
  </BrowserRouter>
);
