import React from "react";
import styled from "styled-components";
import { Review as ReviewType } from "types/index";
import { Text } from "components/text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const Quote = styled.blockquote`
  padding: 0.5rem 1rem;
  margin: 0;
  margin-bottom: 0.5rem;
  border-left: 2px solid ${({ theme }) => theme.palette.primary.main};
`;

const Wrapper = styled.article`
  margin-bottom: 3rem;

  :nth-child(2n) {
    text-align: right;

    ${Quote} {
      border-left: none;
      border-right: 2px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

const Star = styled(FontAwesomeIcon).attrs({
  icon: faStar
})`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const QuoteAuthor = styled(Text)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  margin: 0 2rem;
`;

export const Review: React.FC<ReviewType> = ({ mentee, rating, text }) => {
  const stars = Array(rating)
    .fill("")
    .map((_, index) => <Star />);
  return (
    <Wrapper>
      <Quote>
        <Text>{text} </Text>
      </Quote>
      <QuoteAuthor>
        {mentee} - {stars}
      </QuoteAuthor>
    </Wrapper>
  );
};
