import { Router } from "./Router";
import { GlobalStyle } from "./common/global-styles";
import { theme } from "common/theme";
import { ThemeProvider } from "styled-components";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  );
}
