import { Experience } from "types/index";

// title: string;
// company: string;
// location: string;
// employmentType: string;
// startDate: Date;
// endDate: Date | null;
// description: string[];

export const experience: Experience[] = [
   {
    title: "Senior Frontend Engineer",
    company: "Hopper",
    location: "Remote",
    employmentType: "Permanent Full-time",
    startDate: new Date("2022-06-01"),
    endDate: null,
    description: []
  },
   {
    title: "Podcast Host",
    company: "Loonie Engineering",
    location: "Remote",
    employmentType: "Self-employed",
    startDate: new Date("2022-12-15"),
    endDate: null,
    description: []
  },
  {
    title: "Engineering Coach",
    company: "MentorCruise",
    location: "Remote",
    employmentType: "Freelance",
    startDate: new Date("2020-03-02"),
    endDate: null,
    description: []
  },
   {
    title: "Lead Frontend Engineer",
    company: "Deluxe",
    location: "Remote",
    employmentType: "Permanent Full-time",
    startDate: new Date("2021-10-01"),
    endDate: new Date("2022-06-01"),
    description: []
  },
 
  {
    title: "Tech Lead / Senior Frontend Engineer",
    company: "Jane.com",
    location: "Remote",
    employmentType: "Permanent Full-time",
    startDate: new Date("2021-01-04"),
    endDate: new Date("2021-10-01"),
    description: []
  },
  
  {
    title: "Senior Engineer",
    company: "Finaeo Inc.",
    location: "Remote",
    employmentType: "Permanent Full-time",
    startDate: new Date("2020-07-02"),
    endDate: new Date("2020-12-02"),
    description: []
  },
  {
    title: "Frontend Engineer Consultant",
    company: "Movia",
    location: "Remote",
    employmentType: "Contract Part-time",
    startDate: new Date("2020-06-02"),
    endDate: new Date("2020-11-02"),
    description: []
  },
  {
    title: "Senior Developer",
    company: "Tyger Shark",
    location: "Barrie",
    employmentType: "Permanent Full-time",
    startDate: new Date("2019-07-02"),
    endDate: new Date("2020-07-02"),
    description: []
  },
  {
    title: "Instructor",
    company: "Georgian College",
    location: "Barrie",
    employmentType: "Contract Part-time",
    startDate: new Date("2019-01-02"),
    endDate: new Date("2020-05-02"),
    description: []
  },
  {
    title: "Full Stack Software Developer",
    company: "Smash Reality Inc.",
    location: "Collingwood",
    employmentType: "Contract Part-time",
    startDate: new Date("2018-08-02"),
    endDate: new Date("2019-07-02"),
    description: []
  },
  {
    title: "Full Stack Web Developer",
    company: "LabX Media Group",
    location: "Midland",
    employmentType: "Permanent Full-time",
    startDate: new Date("2017-09-02"),
    endDate: new Date("2018-08-02"),
    description: []
  }
];
