import React from "react";
import styled from "styled-components";
import { Recommendation } from "types/index";
import { Text } from "components/text";

const Quote = styled.blockquote`
  padding: 0.5rem 1rem;
  margin: 0;
  margin-bottom: 0.5rem;
  border-left: 2px solid ${({ theme }) => theme.palette.primary.main};
`;

const Wrapper = styled.article`
  margin-bottom: 3rem;

  :nth-child(2n) {
    text-align: right;

    ${Quote} {
      border-left: none;
      border-right: 2px solid ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

const QuoteAuthor = styled(Text)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
  margin: 0 2rem;
`;

export const RecommendationItem: React.FC<Recommendation> = ({
  person,
  body,
  connection
}) => {
  const lines = Array.isArray(body) ? body : [body];
  return (
    <Wrapper>
      <Quote>
        {lines.map((text, i) => (
          <Text gutterBottom={i + 1 !== lines.length} key={i}>
            {text}
          </Text>
        ))}
      </Quote>
      <QuoteAuthor>{person}</QuoteAuthor>
    </Wrapper>
  );
};
