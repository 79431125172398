import React from "react";
import { MiniHero } from "components/hero";
import { Text } from "components/text";
import { Container } from "components/container";
import { Title } from "components/title";
import { Review } from "components/review";
import { reviews } from "__fixtures__/reviews";

const Mentorship = () => {
  return (
    <div>
      <MiniHero title="Mentoring">
        <Text>Brief summaries of my time mentoring</Text>
      </MiniHero>
      <Container>
        <Title as="h3">MentorCruise</Title>
        <Text gutterBottom>
          I started mentoring on MentorCruise in March of 2020. A couple former
          colleagues had recently joined the platform and I was quite interested
          in it. So I set up a profile not expecting much and was shocked to
          find how many individuals were interested in receiving MentorShip from
          myself.
        </Text>

        <Text gutterBottom>
          I very quickly onboarded 7 mentors across 6 different countries which
          honestly was a lot to handle. Within a short time one of mentee's let
          me know he would be ending his mentorship as working with me had given
          him the confidence he has been looking for.
        </Text>

        <Text gutterBottom>
          Mentoring has given me an outlet for helping others either emerge into
          software engineering or further their careers.
        </Text>

        <Text gutterBottom>Currently I am mentoring two individuals.</Text>

        <Title as="h3">Reviews</Title>

        {reviews.map((review) => (
          <Review key={review.mentee} {...review} />
        ))}
      </Container>
    </div>
  );
};

export default Mentorship;
